import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'; // Corrected import
import router from './router';

// Define your messages for each language
const messages = {
    en: {
        message: {
            "welcome": 'Welcome to AmanLink',
            "welcome2": 'You will get 3 days of free experience.',
            "welcome14": 'You will get 14 days of free experience.',
            "welcomeMsg": 'The vpn that works for you.',
            "email": 'Insert your email to start',
            "hint_email": 'Email',
            "download": 'Download From GooglePlay',
            "download2": 'Download Apk File',
            "msg_email_exist": 'This email address has already registered',
            "invitation_invalid": 'Invitation invalid',
            "invitation_used_up": 'Invitation all used',
            "msg_download_other_platform": 'Invite success.',

            "title_account": 'Delete your account in AmanLink',
            "desc_account": 'By providing your email address & password, you can delete your account in AmanLink. Your data will be deleted permanently.',
            "hint_password": 'Password',
            "delete": 'Delete Account',
            "msg_delete_success": 'Your account has been deleted.',
            "msg_validate_error": 'Account validation failed',

            "buy_vip": "Buy VIP",
            "sendToFriend": "Send to Friends (activation code will be provided after purchase)",
            "buyAmount": "Purchase Quantity",
            "format_day": "Day(s)",
            "format_month": "Month(s)",
            "format_year": "Year",
            "format_sumary": "Equivalent to",
            "pay_payeer": "Pay with payeer",

            "pay_success": "Payment Success",
            "pay_fail": "Payment Failed",
            "column_activation_code": "Activation Code",
            "column_days": "Days",
        }
    },
    zh: {
        "message": {
            "welcome": "欢迎来到AmanLink",
            "welcome2": "您将获得3天的免费体验。",
            "welcome14": "您将获得14天的免费体验。",
            "welcomeMsg": '适合您的VPN',

            "email": "输入您的电子邮件以开始",
            "hint_email": "电子邮件",
            "download": "从GooglePlay下载",
            "download2": "下载Apk文件",
            "msg_email_exist": "该电子邮件地址已经注册",
            "invitation_invalid": "邀请无效",
            "invitation_used_up": "邀请已全部使用",
            "msg_download_other_platform": "邀请成功。",

            "title_account": "删除您在AmanLink中的账户",
            "desc_account": "提供您的电子邮件地址和密码，您可以删除在AmanLink中的账户。您的数据将被永久删除。",
            "hint_password": "密码",
            "delete": "删除账户",
            "msg_delete_success": "您的账户已被删除。",
            "msg_validate_error": "账户验证失败",

            "buy_vip": "购买会员",
            "sendToFriend": "赠送给朋友（购买成功后将会以激活码的方式提供）",
            "buyAmount": "购买数量",
            "format_day": "天",
            "format_month": "个月",
            "format_year": "年",
            "format_sumary": "折合",
            "pay_payeer": "付款 (使用 payeer)",

            "pay_success": "支付成功",
            "pay_fail": "支付失败",
            "column_activation_code": "激活码",
            "column_days": "天"
        }
    },
    ar: {
        "message": {
            "welcome": "مرحبا بك في AmanLink",
            "welcome2": "ستحصل على تجربة مجانية لمدة 3 أيام.",
            "welcome14": "ستحصل على تجربة مجانية لمدة14 أيام.",
            "welcomeMsg": 'الشبكة الافتراضية الخاصة التي تناسبك.',
            "email": "أدخل بريدك الإلكتروني للبدء",
            "hint_email": "البريد الإلكتروني",
            "download": "تحميل من جوجل بلاي",
            "download2": "تنزيل ملف Apk",
            "msg_email_exist": "عنوان البريد الإلكتروني هذا مسجل بالفعل",
            "invitation_invalid": "الدعوة غير صالحة",
            "invitation_used_up": "تم استخدام جميع الدعوات",
            "msg_download_other_platform": "نجاح الدعوة.",

            "title_account": "حذف حسابك في AmanLink",
            "desc_account": "بتقديم عنوان بريدك الإلكتروني وكلمة المرور، يمكنك حذف حسابك في AmanLink. سيتم حذف بياناتك بشكل دائم.",
            "hint_password": "كلمة المرور",
            "delete": "حذف الحساب",
            "msg_delete_success": "تم حذف حسابك.",
            "msg_validate_error": "فشل في التحقق من الحساب",

            "buy_vip": "شراء VIP",
            "sendToFriend": "إرسال إلى الأصدقاء (سيتم توفير رمز التفعيل بعد الشراء)",
            "buyAmount": "كمية الشراء",
            "format_day": "يوم",
            "format_month": "شهر",
            "format_year": "سنة",
            "format_sumary": "ما يعادل",
            "pay_payeer": "الدفع بواسطة Payeer",

            "pay_success": "تم الدفع بنجاح",
            "pay_fail": "فشل الدفع",
            "column_activation_code": "رمز التفعيل",
            "column_days": "أيام"
        }
    },
    ru: {
        "message": {
            "welcome": "Добро пожаловать в AmanLink",
            "welcome2": "Вы получите 3 дня бесплатного опыта.",
            "welcome14": "Вы получите 14 дня бесплатного опыта.",
            "welcomeMsg": 'VPN, который работает для вас.',
            "email": "Введите вашу электронную почту, чтобы начать",
            "hint_email": "Электронная почта",
            "download": "Скачать с Google Play",
            "download2": "Скачать файл Apk",
            "msg_email_exist": "Этот адрес электронной почты уже зарегистрирован",
            "invitation_invalid": "Приглашение недействительно",
            "invitation_used_up": "Все приглашения использованы",
            "msg_download_other_platform": "Приглашение успешно.",

            "title_account": "Удалить ваш аккаунт в AmanLink",
            "desc_account": "Предоставив ваш адрес электронной почты и пароль, вы можете удалить ваш аккаунт в AmanLink. Ваши данные будут удалены навсегда.",
            "hint_password": "Пароль",
            "delete": "Удалить аккаунт",
            "msg_delete_success": "Ваш аккаунт был удален.",
            "msg_validate_error": "Проверка аккаунта не удалась",

            "buy_vip": "Купить VIP",
            "sendToFriend": "Отправить друзьям (код активации будет предоставлен после покупки)",
            "buyAmount": "Количество покупки",
            "format_day": "День",
            "format_month": "Месяц",
            "format_year": "Год",
            "format_sumary": "Эквивалентно",
            "pay_payeer": "Оплатить через Payeer",

            "pay_success": "Оплата успешна",
            "pay_fail": "Ошибка оплаты",
            "column_activation_code": "Код активации",
            "column_days": "Дни"
        }
    },
    fa: {
        "message": {
            "welcome": "به AmanLink خوش آمدید",
            "welcome2": "شما 3 روز تجربه رایگان خواهید داشت.",
            "welcome14": "شما 14 روز تجربه رایگان خواهید داشت.",
            "welcomeMsg": 'وی‌پی‌انی که برای شما کار می‌کند.',
            "email": "برای شروع ایمیل خود را وارد کنید",
            "hint_email": "ایمیل",
            "download": "از گوگل پلی دانلود کنید",
            "download2": "دانلود فایل Apk",
            "msg_email_exist": "این آدرس ایمیل قبلا ثبت شده است",
            "invitation_invalid": "دعوت نامعتبر است",
            "invitation_used_up": "تمام دعوت‌ها استفاده شده‌اند",
            "msg_download_other_platform": "دعوت موفقیت‌آمیز بود.",

            "title_account": "حذف حساب کاربری شما در AmanLink",
            "desc_account": "با ارائه آدرس ایمیل و رمز عبور خود، می‌توانید حساب کاربری خود را در AmanLink حذف کنید. داده‌های شما به طور دائم حذف خواهند شد.",
            "hint_password": "رمز عبور",
            "delete": "حذف حساب",
            "msg_delete_success": "حساب کاربری شما حذف شد.",
            "msg_validate_error": "اعتبارسنجی حساب ناموفق بود",

            "buy_vip": "خرید VIP",
            "sendToFriend": "ارسال به دوستان (کد فعال‌سازی پس از خرید ارائه خواهد شد)",
            "buyAmount": "تعداد خرید",
            "format_day": "روز",
            "format_month": "ماه",
            "format_year": "سال",
            "format_sumary": "معادل",
            "pay_payeer": "پرداخت با Payeer",

            "pay_success": "پرداخت موفقیت‌آمیز بود",
            "pay_fail": "پرداخت ناموفق بود",
            "column_activation_code": "کد فعال‌سازی",
            "column_days": "روز"

        }
    },
    tk: {
        message: {
            "welcome": 'AmanLink-a Hoş Geldiňiz',
            "welcome2": 'Siz 3 günlük mugt synagy alarsyňyz.',
            "welcome14": 'Siz 14 günlük mugt synagy alarsyňyz.',
            "welcomeMsg": 'Siziň üçin işleýän VPN.',
            "email": 'Başlamak üçin emailiňizi giriziň',
            "hint_email": 'Email',
            "download": 'GooglePlay-den Ýükle',
            "download2": 'Apk Faýlyny Ýükle',
            "msg_email_exist": 'Bu email salgysy eýýäm hasaba alyndy',
            "invitation_invalid": 'Çakylyk nädogry',
            "invitation_used_up": 'Çakylyklar hemmesi ulanyldy',
            "msg_download_other_platform": 'Çakylyk üstünlikli.',

            "title_account": 'AmanLink-de hasabyňyzy öçüriň',
            "desc_account": 'Email salgyňyzy we parolyňyzy üpjün etmek bilen, AmanLink-de hasabyňyzy öçürip bilersiňiz. Maglumatyňyz hemişelik öçüriler.',
            "hint_password": 'Parol',
            "delete": 'Hasaby Öçürmek',
            "msg_delete_success": 'Hasabyňyz öçürildi.',
            "msg_validate_error": 'Hasap barlagy şowsuz boldy',

            "buy_vip": "VIP satyn almak",
            "sendToFriend": "Dostlara ibermek (satyn alandan soň aktivasiýa kody beriler)",
            "buyAmount": "Satyn alyş mukdary",
            "format_day": "Gün",
            "format_month": "Aý",
            "format_year": "Ýyl",
            "format_sumary": "Deň",
            "pay_payeer": "Payeer bilen tölemek",

            "pay_success": "Töleg üstünlikli",
            "pay_fail": "Töleg şowsuz",
            "column_activation_code": "Aktiwasiýa kody",
            "column_days": "Günler"
        }
    },
    tr: {
        message: {
            "welcome": "AmanLink'e Hoş Geldiniz",
            "welcome2": "3 gün ücretsiz deneyim kazanacaksınız.",
            "welcome14": "14 gün ücretsiz deneyim kazanacaksınız.",
            "welcomeMsg": "Sizin için çalışan VPN.",
            "email": "Başlamak için e-posta adresinizi girin",
            "hint_email": "E-posta",
            "download": "GooglePlay'den İndir",
            "download2": "APK Dosyasını İndir",
            "msg_email_exist": "Bu e-posta adresi zaten kayıtlı",
            "invitation_invalid": "Davet geçersiz",
            "invitation_used_up": "Tüm davetler kullanıldı",
            "msg_download_other_platform": "Davet başarılı.",
            "title_account": "AmanLink'te hesabınızı silin",
            "desc_account": "E-posta adresinizi ve şifrenizi sağlayarak AmanLink'teki hesabınızı silebilirsiniz. Verileriniz kalıcı olarak silinecek.",
            "hint_password": "Şifre",
            "delete": "Hesabı Sil",
            "msg_delete_success": "Hesabınız silindi.",
            "msg_validate_error": "Hesap doğrulama başarısız",
            "buy_vip": "VIP Satın Al",
            "sendToFriend": "Arkadaşlara Gönder (satın alma işleminden sonra etkinleştirme kodu sağlanacaktır)",
            "buyAmount": "Satın Alma Miktarı",
            "format_day": "Gün",
            "format_month": "Ay",
            "format_year": "Yıl",
            "format_sumary": "Eşdeğer",
            "pay_payeer": "Payeer ile Öde",

            "pay_success": "Ödeme Başarılı",
            "pay_fail": "Ödeme Başarısız",
            "column_activation_code": "Aktivasyon Kodu",
            "column_days": "Günler"
        }
    },
    id: {
        message: {
            "welcome": "Selamat Datang di AmanLink",
            "welcome2": "Anda akan mendapatkan pengalaman gratis selama 3 hari.",
            "welcome14": "Anda akan mendapatkan pengalaman gratis selama 14 hari.",
            "welcomeMsg": "VPN yang bekerja untuk Anda.",
            "email": "Masukkan email Anda untuk memulai",
            "hint_email": "Email",
            "download": "Unduh dari GooglePlay",
            "download2": "Unduh File Apk",
            "msg_email_exist": "Alamat email ini sudah terdaftar",
            "invitation_invalid": "Undangan tidak valid",
            "invitation_used_up": "Semua undangan telah digunakan",
            "msg_download_other_platform": "Undangan berhasil.",
            "title_account": "Hapus akun Anda di AmanLink",
            "desc_account": "Dengan memberikan alamat email & kata sandi Anda, Anda dapat menghapus akun Anda di AmanLink. Data Anda akan dihapus secara permanen.",
            "hint_password": "Kata Sandi",
            "delete": "Hapus Akun",
            "msg_delete_success": "Akun Anda telah dihapus.",
            "msg_validate_error": "Validasi akun gagal",
            "buy_vip": "Beli VIP",
            "sendToFriend": "Kirim ke Teman (kode aktivasi akan diberikan setelah pembelian)",
            "buyAmount": "Jumlah Pembelian",
            "format_day": "Hari",
            "format_month": "Bulan",
            "format_year": "Tahun",
            "format_sumary": "Setara dengan",
            "pay_payeer": "Bayar dengan Payeer",

            "pay_success": "Pembayaran Berhasil",
            "pay_fail": "Pembayaran Gagal",
            "column_activation_code": "Kode Aktivasi",
            "column_days": "Hari"
        }

    }
    // Add more languages here
};

// Create i18n instance with options
const i18n = createI18n({
    legacy: false, // you must specify 'legacy: false' option
    locale: navigator.language || navigator.userLanguage, // This will set the locale based on the browser's language
    fallbackLocale: 'en', // Fallback to English if the browser's language is not supported
    messages, // Your translations
});

createApp(App).use(router).use(i18n).mount('#app')
