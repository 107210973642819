import { createRouter, createWebHistory } from 'vue-router';
import VpnInvitation from './pages/VpnInvitation.vue'
import VpnInvitation2 from './pages/VpnInvitation2.vue'
// import Home from './pages/Home.vue';
import VpnAccount from './pages/VpnAccount.vue';
import MainPage from './pages/MainPage.vue';
import BuyActivationCode from './pages/BuyActivationCode.vue';
import PaymentSuccess from './pages/PaymentSuccess.vue';
import PaymentFail from './pages/PaymentFail.vue';

const routes = [
    {
        path: '/invitation',
        name: 'Invitation',
        component: VpnInvitation2
    },
    {
        path: '/invitation2',
        name: 'Invitation2',
        component: VpnInvitation
    },
    {
        path: '/account',
        name: 'account',
        component: VpnAccount
    },
    {
        path: '/',
        name: 'Home',
        component: MainPage
    },
    {
        path: '/buy_activation_code',
        name: 'buy_activation_code',
        component: BuyActivationCode
    },
    {
        path: '/payeer_success',
        name: 'payeer_success',
        component: PaymentSuccess
    },
    {
        path: '/payeer_fail',
        name: 'payeer_fail',
        component: PaymentFail
    },

    { path: '/:pathMatch(.*)*', redirect: '/' }, // Catch-all redirect to root path
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;